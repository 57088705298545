import { useState, useEffect } from "react";

// Animation style
const styles = {
    transition: 'all 2000ms east-out'
}

// Node settings
const defaultBkg = "#000000";
const activeBkg = "#000000";
const defaultScale = 1;
const activeScale = 1.2;

// Display infromation on click
const useDisplayInfo = () => {

    // Default settings
    const [nodeActive, setActive] = useState(false);
    const [nodeBkg, setBkg] = useState(defaultBkg);
    const [nodeScale, setScale] = useState(defaultScale);

    // Toggle information display/hide
    const toggleInfo = () => {
        nodeActive === true ? setActive(false) : setActive(true); 
    }

    // Change appearance and info display when click
    useEffect(() => {
        if (nodeActive) {
            setBkg(activeBkg);
            setScale(activeScale);
        }
        else {
            setBkg(defaultBkg);
            setScale(defaultScale);
        }
    }, [nodeActive]);

    return [toggleInfo, nodeActive, nodeBkg, nodeScale];
}

// Render node
const AppInfoNode = () => {
    const [toggleInfo, nodeActive, nodeBkg, nodeScale] = useDisplayInfo();

    return (
        <div className='music-node-container' 
                style={{
                    ...styles,
                    left: "200px",
                    top: "250px"
                }}>
                <div className="app-info-node-player"
                    onClick={toggleInfo}
                    style={{
                        ...styles,
                        backgroundColor: nodeBkg,
                        transform: 'scale(' + nodeScale + ')',
                        zIndex: nodeActive === true ? 2 : 1
                    }}>
                </div>
                <div className="app-info-music-node"
                    style={{
                        ...styles,
                        opacity: nodeActive === true ? 1 : 0,
                        zIndex: nodeActive === true ? 3 : -1
                    }}>
                    <p>
                        h a l f f l o o r <br/> the app
                    </p>
                    <p>
                        press the icon <br/> to download 
                    </p>
                    <p>
                        n.b. <br/> only for androids <br/> ↓
                    </p>
                    <a
                        href="https://www.halffloor.com/appen/HalffloorApp.apk"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="app-link" />
                    </a>
                </div>
            </div>
    )
}

export default AppInfoNode